/* eslint-disable no-console */
import 'firebase/messaging';
import firebase from 'firebase/app';
import { RegistrationApi } from '@api/business/api.utils';
import { PlcDispatch, PlcStore, RootState } from '@store/store';
import { isServer } from '@utils/server';
import { safeJsonParse } from '@utils/strings';

export const MAX_PREFERENCES_PRODUCT_TYPES = 6;
export const MAX_PREFERENCES_SERVICES = 6;

function getConfig(): Record<string, string> | void {
  try {
    if (process.env.NEXT_STATIC_FIREBASE_CONFIG) {
      return safeJsonParse(process.env.NEXT_STATIC_FIREBASE_CONFIG);
    }
    throw new Error('Unable to get firebase env variable');
  } catch (err) {
    console.error('Unable to get firebase config', err);
  }
  return undefined;
}

function initFirebaseMessaging(): firebase.messaging.Messaging | undefined {
  const config = getConfig();

  if (config && process.env.NEXT_STATIC_FIREBASE_VAPID) {
    // Initialize Firebase
    firebase.initializeApp(config);
    // Retrieve Firebase Messaging object.
    const messaging = firebase.messaging();
    messaging.usePublicVapidKey(process.env.NEXT_STATIC_FIREBASE_VAPID);
    return messaging;
  }

  return undefined;
}

let messaging: firebase.messaging.Messaging | undefined;

if (!isServer() && firebase.messaging.isSupported() && !messaging) {
  messaging = initFirebaseMessaging();
}

function isTokenSentToServer() {
  return window.localStorage.getItem('sentToServer') === '1';
}

function setTokenSentToServer(sent: boolean) {
  window.localStorage.setItem('sentToServer', sent ? '1' : '0');
}

async function updateToken(token: string, forceServerUpdate = false) {
  if (forceServerUpdate || !isTokenSentToServer()) {
    try {
      await RegistrationApi.insertRegistrationToken({ token });
      setTokenSentToServer(true);
    } catch (err) {
      setTokenSentToServer(false);
      console.error('Error when sending token to api.', err);
    }
  }
}

export async function updatePushNotification(rootState: RootState, dispatch: PlcDispatch) {
  const { settings } = rootState;

  if (!messaging || !settings.preferences.pushNotificationEnabled) {
    return;
  }

  dispatch.settings.setPushNotificationError(undefined);

  messaging.onMessage(payload => {
    console.info('Foreground push !', payload);
    const title = payload.notification.title || 'Pleinchamp';
    const { body, image: icon } = payload.notification;
    // eslint-disable-next-line no-new
    new Notification(title, { body, icon });
  });

  messaging.onTokenRefresh(async () => {
    if (messaging) {
      let token;
      try {
        token = await messaging.getToken();
      } catch (err) {
        console.error('An error occurred while retrieving refreshed token. ', err);
        dispatch.settings.setPushNotificationError(err.message);
        setTokenSentToServer(false);
      }

      if (token) {
        updateToken(token, true);
      }
    }
  });

  let permission;
  try {
    permission = await Notification.requestPermission();
  } catch (err) {
    console.error('Error getting permissions.', err);
    dispatch.settings.setPushNotificationError(err.message);
    return;
  }

  if (permission !== 'granted') {
    console.warn('Notification permission denied.');
    dispatch.settings.setPushNotificationDenied(true);
    return;
  }

  dispatch.settings.setPushNotificationDenied(false);

  let token;
  try {
    token = await messaging.getToken();
  } catch (err) {
    console.error('An error occurred while retrieving token.', err);
    dispatch.settings.setPushNotificationError(err.message);
    setTokenSentToServer(false);
  }

  if (token) {
    updateToken(token);
  }
}

export async function fetchUserProductTypes(store: PlcStore) {
  await store.dispatch.settings.fetchUserProductTypes();
}

/* eslint-disable react/button-has-type */
import React, { ComponentPropsWithRef, FC, forwardRef } from 'react';
import classnames from 'classnames';

import './BasicButton.scss';

const BasicButton: FC<ComponentPropsWithRef<'button'> & { wrapperClassName?: string }> = forwardRef(
  ({ className, wrapperClassName, onClick, type, children, ...rest }, buttonRef) => {
    const onKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (onClick && (event.key === 'Enter' || event.key === ' ')) {
        onClick((event as unknown) as React.MouseEvent<HTMLButtonElement>);
      }
    };
    const wrapperClasses = classnames('basic-button-wrapper', wrapperClassName);
    const rootClassname = classnames('basic-button', className);

    return (
      <button
        ref={buttonRef}
        className={wrapperClasses}
        onClick={onClick}
        onKeyDown={onKeyDown}
        type={type || 'button'}
        {...rest}
      >
        <span className={rootClassname} tabIndex={-1}>
          {children}
        </span>
      </button>
    );
  }
);

export { BasicButton };

import { BasicButton } from '../BasicButton/BasicButton.component';
import React, { ComponentPropsWithRef, FC, forwardRef } from 'react';

import './IconButton.scss';

const IconButton: FC<ComponentPropsWithRef<'button'>> = forwardRef(
  ({ className, type = 'button', ...rest }, buttonRef) => {
    return (
      <BasicButton
        ref={buttonRef}
        className={`icon-wrapper ${className}`}
        type={type as ComponentPropsWithRef<'button'>['type']}
        {...rest}
      />
    );
  }
);

export { IconButton };

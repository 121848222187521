import { TagCommanderService } from 'features/tagCommander/business/TagCommander.service';
import { LinkProps } from 'next/link';
import React, { ComponentPropsWithoutRef, FC, forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TextWithArrowProps } from '@components/Text/Text.types';
import { TextWithArrow } from '@components/Text/TextWithArrow.component';
import { PlcAnchor } from '@layout/components/PlcAnchor/PlcAnchor.component';
import classnames from 'classnames';

import './BasicLink.scss';

type Props = {
  isUnderlined?: boolean;
  textProps?: Omit<TextWithArrowProps, 'tag' | 'variant'> & { variant?: TextWithArrowProps['variant'] };
  as?: LinkProps['as'];
  href?: LinkProps['href'];
  prefetch?: LinkProps['prefetch'];
  withAuth?: boolean;
  wrapperClassName?: string;
} & Omit<ComponentPropsWithoutRef<'a'>, 'href'>;

const BasicLink: FC<Props> = forwardRef(
  (
    {
      isUnderlined = false,
      textProps = {},
      href,
      children,
      className,
      as,
      prefetch,
      withAuth,
      wrapperClassName,
      ...rest
    },
    linkRef
  ) => {
    const { variant, withArrow = false, ...restTextProps } = textProps;

    const rootClassName = classnames('basic-link', 'cursor-pointer', isUnderlined ? 'underlined-link' : '', className);
    const wrapperClasses = classnames('basic-link-wrapper', wrapperClassName);

    const { i18nKey, i18nOptions } = restTextProps;

    const i18nKeyNs = i18nKey ? i18nKey.split('.')[0] : 'common';
    const { t } = useTranslation(i18nKeyNs);

    const pageName = i18nKey && t(i18nKey, i18nOptions);
    const pageUrl = as?.toString();

    const tagEventMenuNavigation = (event: EventTarget) => {
      // PlcAnchor Component already implement the TAG Commander business logic.
      // So no need to do twice the calls.
      if (!href) {
        const currentTcVar = TagCommanderService.getCurrentTcVars();

        TagCommanderService.updateTcVars({
          ...currentTcVar,
          page_name: pageName,
          page_url: pageUrl,
        });
        TagCommanderService.setTcNavigationEvent(event);
      }
    };

    const innerHtml = useMemo(() => {
      function buildVariant() {
        if (!variant) {
          if (isUnderlined) return 'small';
          return 'p';
        }
        return variant;
      }
      return (
        <TextWithArrow
          className={rootClassName}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={event => tagEventMenuNavigation(event.currentTarget)}
          tabIndex={-1}
          tag="span"
          variant={buildVariant() as TextWithArrowProps['variant']}
          withArrow={withArrow}
          {...restTextProps}
        >
          {children}
        </TextWithArrow>
      );
    }, [rootClassName, isUnderlined, withArrow, restTextProps, children]);

    return href ? (
      <PlcAnchor
        className={wrapperClasses}
        linkProps={{ as, href, prefetch }}
        withAuth={withAuth}
        {...rest}
        ref={linkRef as React.Ref<HTMLAnchorElement>}
      >
        {innerHtml}
      </PlcAnchor>
    ) : (
      <span className="basic-link-wrapper" {...rest}>
        {innerHtml}
      </span>
    );
  }
);

export { BasicLink };

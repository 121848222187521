import { Text } from './Text.component';
import React, { FC, forwardRef } from 'react';
import { ArrowNextIcon } from '@icons/ArrowNext.icon';
import { useTranslation } from '@locales/useTranslation.hook';
import classnames from 'classnames';
import { HTMLPropsHeaders, TextWithArrowProps } from './Text.types';

import './Text.scss';

const TextWithArrow: FC<TextWithArrowProps & HTMLPropsHeaders> = forwardRef(
  (
    { withArrow = true, variant, flavour, alignment, tag, children, className, i18nKey, i18nOptions, ...rest },
    textRef
  ) => {
    const i18nKeyNs = i18nKey ? i18nKey.split('.')[0] : 'common';
    const { t } = useTranslation(i18nKeyNs);

    const rootClassName = classnames('text-with-arrow', className);
    return (
      <Text
        ref={textRef}
        alignment={alignment}
        className={rootClassName}
        flavour={flavour}
        tag={tag}
        variant={variant}
        {...rest}
      >
        {i18nKey && t(i18nKey, i18nOptions)}
        {children}
        {withArrow && <ArrowNextIcon className="next-icon" height={24} width={24} />}
      </Text>
    );
  }
);

export { TextWithArrow };
